import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Navigate             from "Dashboard/Core/Navigate";
import Hooks                from "Utils/Hooks";

// Components
import WidgetMain           from "./WidgetMain";
import WidgetChat           from "./WidgetChat";
import WidgetPlatforms      from "./WidgetPlatforms";
import WidgetIcon           from "./WidgetIcon";

// Dashboard
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";



// Styles
const Container = Styled.main`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: var(--page-height);
    border: var(--border-width) solid var(--border-color-light);
    border-radius: var(--border-radius);
    padding: 16px;
    padding-top: 0;
    overflow: auto;
`;

const Tabs = Styled(TabList)`
    position: sticky;
    top: 0;
    margin-bottom: -16px;
    padding-top: 16px;
    padding-bottom: 8px;
    background-color: white;
    z-index: 2;
`;



/**
 * The Widget Options
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function WidgetOptions(props) {
    const { onChange, onColor, onClear, forceClear, getText } = props;

    const { widgetID, widgetTab } = Navigate.useParams();
    const navigate = Hooks.useGoto();


    // Handles the Tab
    const handleTab = (url) => {
        navigate("SETTINGS", "WIDGETS", widgetID, url);
    };


    // Variables
    const isMain      = Navigate.isUrl("MAIN", widgetTab);
    const isChat      = Navigate.isUrl("CHAT", widgetTab);
    const isPlatforms = Navigate.isUrl("PLATFORMS", widgetTab);
    const isIcon      = Navigate.isUrl("ICON", widgetTab);


    // Do the Render
    return <Container>
        <Tabs selected={widgetTab} onClick={handleTab}>
            <TabItem
                message="GENERAL_MAIN"
                url="MAIN"
            />
            <TabItem
                message="WIDGETS_CHAT"
                url="CHAT"
            />
            <TabItem
                message="WIDGETS_PLATFORMS_NAME"
                url="PLATFORMS"
            />
            <TabItem
                message="WIDGETS_ICON"
                url="ICON"
            />
        </Tabs>

        {isMain && <WidgetMain
            onChange={onChange}
            onColor={onColor}
            onClear={onClear}
            forceClear={forceClear}
            getText={getText}
        />}
        {isChat && <WidgetChat
            onChange={onChange}
            onColor={onColor}
            onClear={onClear}
            forceClear={forceClear}
            getText={getText}
        />}
        {isPlatforms && <WidgetPlatforms
            onChange={onChange}
            onClear={onClear}
            forceClear={forceClear}
            getText={getText}
        />}
        {isIcon && <WidgetIcon
            onChange={onChange}
            onClear={onClear}
            forceClear={forceClear}
            getText={getText}
        />}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
WidgetOptions.propTypes = {
    onChange   : PropTypes.func.isRequired,
    onColor    : PropTypes.func.isRequired,
    onClear    : PropTypes.func.isRequired,
    forceClear : PropTypes.func.isRequired,
    getText    : PropTypes.func.isRequired,
};

export default WidgetOptions;

import React                from "react";
import PropTypes            from "prop-types";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import Hooks                from "Utils/Hooks";

// Components
import ChannelEdit          from "./Dialogs/ChannelEdit";
import ChannelProfile       from "./Dialogs/ChannelProfile";
import ChannelIcon          from "Components/Utils/Common/ChannelIcon";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Channel List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ChannelList(props) {
    const { type } = props;
    const navigate = Hooks.useGoto();

    const { fetch, load } = useList("channel", type);

    const data = Store.useState("channel");
    const { loading, canEdit, list, total, sort } = data;

    const { deleteChannel } = Store.useAction("channel");

    // The Current State
    const [ deleting, setDeleting ] = React.useState(false);
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Action
    const handleAction = (action, elemID) => {
        if (action.isCreate) {
            navigate("SETTINGS", "CHANNELS", "ADD");
        } else if (action.isView) {
            const url = Utils.getValue(list, "channelID", elemID, "channelUrl");
            Navigate.gotoUrl(url, true);
        } else {
            startAction(action, elemID);
        }
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        setDeleting(true);
        const result = await deleteChannel(elemID);
        if (result.success) {
            endAction();
            fetch();
        }
        setDeleting(false);
    };

    // Returns true if the Profile is hidden
    const hideProfile = (elemID) => {
        const hasProfile = Utils.getValue(list, "channelID", elemID, "hasProfile");
        return !canEdit || !hasProfile;
    };


    // Do the Render
    const elemName = Utils.getValue(list, "channelID", elemID, "name");

    return <Main>
        <Header message="CHANNELS_NAME" icon="channel">
            <ActionList data={data} onAction={handleAction} useAdd />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="CHANNELS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="providerName" message="CHANNELS_SINGULAR" maxWidth="140" isTitle isFlex smallSpace />
                    <TableHeader field="name"         message="GENERAL_NAME"      grow="2" />
                    <TableHeader field="info"         message="GENERAL_INFO"      grow="2" />
                    <TableHeader field="flowName"     message="FLOWS_SINGULAR"    />
                    <TableHeader field="status"       message="GENERAL_STATUS"    maxWidth="70" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.channelID} elemID={elem.channelID}>
                        <TableCell>
                            <ChannelIcon
                                icon={elem.providerIcon}
                                color={elem.providerColor}
                                size={24}
                            />
                            {elem.providerName}
                        </TableCell>
                        <TableCell message={elem.name}       />
                        <TableCell message={elem.info}       />
                        <TableCell message={elem.flowName}   />
                        <TableCell message={elem.statusName} textColor={elem.statusColor} />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="EDIT"    message="CHANNELS_EDIT_TITLE"    />
                    <TableAction action="PROFILE" message="CHANNELS_PROFILE_TITLE" hide={hideProfile} />
                    <TableAction action="VIEW"    message="CHANNELS_VIEW_TITLE"    />
                    <TableAction action="DELETE"  message="CHANNELS_DELETE_TITLE"  />
                </TableActionList>
            </Table>
        </Content>

        <ChannelEdit
            open={action.isVE}
            elemID={elemID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <ChannelProfile
            open={action.isProfile}
            elemID={elemID}
            onSubmit={endAction}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            isLoading={deleting}
            title="CHANNELS_DELETE_TITLE"
            message="CHANNELS_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ChannelList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default ChannelList;

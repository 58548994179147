import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Hooks                from "Utils/Hooks";

// Components
import ReportsHeader        from "Components/App/Dashboard/Reports/ReportsHeader";
import ReportsContent       from "Components/App/Dashboard/Reports/ReportsContent";
import UsersContent         from "Components/App/Dashboard/Users/UsersContent";
import TeamsContent         from "Components/App/Dashboard/Teams/TeamsContent";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";



/**
 * The Dashboard Client Page
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DashboardClientPage(props) {
    const { type } = props;

    const { dashboardTab } = Navigate.useParams();
    const navigate = Hooks.useGoto();

    const { isAgent } = Store.useState("auth");


    // Handle the Tab
    React.useEffect(() => {
        if (!dashboardTab) {
            navigate("DASHBOARD", "REPORTS");
        }
    }, [ dashboardTab ]);

    // Handles the Tab
    const handleTab = (url) => {
        navigate("DASHBOARD", url);
    };


    // Variables
    const withNavigation = type === "CLIENT";
    const isReports      = Navigate.isUrl("REPORTS", dashboardTab);
    const isUsers        = Navigate.isUrl("USERS", dashboardTab);
    const isTeams        = Navigate.isUrl("TEAMS", dashboardTab);


    // Do the Render
    return <Main withNavigation={withNavigation}>
        <Header message="DASHBOARD_NAME" icon="dashboard">
            {isReports && <ReportsHeader />}
        </Header>

        <Content className="light-scrollbars">
            <TabList
                isHidden={isAgent}
                selected={dashboardTab || NLS.url("REPORTS")}
                onClick={handleTab}
            >
                <TabItem
                    message="DASHBOARD_REPORTS"
                    url="REPORTS"
                />
                <TabItem
                    message="USERS_NAME"
                    url="USERS"
                />
                <TabItem
                    message="TEAMS_NAME"
                    url="TEAMS"
                />
            </TabList>

            <ReportsContent
                isHidden={!isReports}
                type={type}
                withNavigation={withNavigation}
                withLinks
                hasTabs
            />
            <UsersContent
                isHidden={!isUsers}
                type={type}
            />
            <TeamsContent
                isHidden={!isTeams}
                type={type}
            />
        </Content>
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DashboardClientPage.propTypes = {
    type : PropTypes.string.isRequired,
};

export default DashboardClientPage;

import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import Hooks                from "Utils/Hooks";

// Components
import TeamEdit             from "./TeamEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";
import ColorCircle          from "Dashboard/Components/Common/ColorCircle";



/**
 * The Team List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function TeamList(props) {
    const { type } = props;
    const clientID = Hooks.useClientID();

    const { fetch, load } = useList("team", type);

    const data = Store.useState("team");
    const { loading, canEdit, list, total, sort } = data;

    const { deleteTeam } = Store.useAction("team");

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteTeam(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Do the Render
    const withNavigation = type === "CLIENT";
    const elemName       = Utils.getValue(list, "teamID", elemID, "name");

    return <Main withNavigation={withNavigation}>
        <Header message="TEAMS_NAME" icon="team">
            <ActionList data={data} onAction={startAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="TEAMS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="name"                 message="GENERAL_NAME"                isTitle isFlex smallSpace />
                    <TableHeader field="userCount"            message="USERS_NAME"                  maxWidth="100" align="center" />
                    <TableHeader field="status"               message="GENERAL_STATUS"              maxWidth="70" />
                    <TableHeader field="qualificationPercent" message="CONVERSATIONS_QUALIFICATION" maxWidth="100" align="center" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.teamID} elemID={elem.teamID}>
                        <TableCell>
                            <ColorCircle color={elem.color} />
                            {elem.name}
                        </TableCell>
                        <TableCell message={elem.usersText}  />
                        <TableCell message={elem.statusName} textColor={elem.statusColor} />
                        <TableCell
                            className={elem.qualificationColor}
                            message={elem.qualificationPercent}
                            tooltip={elem.qualificationTooltip}
                        />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="EDIT"   message="TEAMS_EDIT_TITLE"   />
                    <TableAction action="DELETE" message="TEAMS_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <TeamEdit
            open={action.isVCE}
            elemID={elemID}
            clientID={clientID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="TEAMS_DELETE_TITLE"
            message="TEAMS_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
TeamList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default TeamList;

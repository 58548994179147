import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import Utils                from "Dashboard/Utils/Utils";
import Hooks                from "Utils/Hooks";

// Components
import WidgetPreview        from "./WidgetPreview";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";



// Styles
const Tabs = Styled(TabList)`
    top: -2px;
    display: flex;
    justify-content: center;
    margin-top: -2px;
    padding: 8px;
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);
    background: white;
    z-index: 10;
`;

const Container = Styled.main`
    display: flex;
    justify-content: center;
    gap: var(--main-padding);
    padding: 12px 0 0;

    main {
        --widget-height: auto;
    }

    @media screen and (max-width: 950px) {
        flex-direction: column;
        align-items: center;
        gap: 12px;
    }
`;

const H2 = Styled.h2`
    margin-top: 0px;
    text-align: center;
    color: var(--black-color);
`;



/**
 * The Widget Publish Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function WidgetPublish(props) {
    const { open, currTongueID, data, onClose, onSubmit, onClear } = props;

    const { elem, options, platforms, platformsDraft, tongues } = Store.useState("widget");

    // The Current State
    const [ showClear, setShowClear ] = React.useState(false);
    const [ tongueID,  setTongueID  ] = Hooks.useTongue(tongues);


    // Hide the Clear Changes popup
    React.useEffect(() => {
        setShowClear(false);
        setTongueID(currTongueID);
    }, [ open ]);

    // Returns the Text for the given Property
    const getText = (property) => {
        if (options[property] && Utils.isObject(options[property])) {
            return options[property][tongueID] || "";
        }
        return "";
    };

    // Returns the Text for the given Property
    const getDraftText = (property) => {
        if (data[property] && Utils.isObject(data[property])) {
            return data[property][tongueID] || "";
        }
        return "";
    };



    // Do the Render
    return <>
        <EditDialog
            open={open}
            icon="publish"
            title="GENERAL_PUBLISH_CHANGES"
            onSubmit={onSubmit}
            onClose={onClose}
            primary="GENERAL_PUBLISH"
            secondary="GENERAL_CLEAR"
            onSecondary={() => setShowClear(true)}
            isWide
        >
            <Tabs
                isHidden={tongues.length < 2}
                selected={tongueID}
                onClick={setTongueID}
                inDialog
            >
                {tongues.map(({ key, value }) => <TabItem
                    key={key}
                    message={value}
                    value={key}
                />)}
            </Tabs>

            <Container>
                <div>
                    <H2>{NLS.get("WIDGETS_ACTUAL_VERSION")}</H2>
                    <WidgetPreview
                        options={options}
                        platforms={platforms}
                        getText={getText}
                        isPublish
                    />
                </div>

                <div>
                    <H2>{NLS.get("WIDGETS_MODIFIED_VERSION")}</H2>
                    <WidgetPreview
                        options={data}
                        platforms={platformsDraft}
                        getText={getDraftText}
                        isPublish
                    />
                </div>
            </Container>
        </EditDialog>

        <ConfirmDialog
            open={open && showClear}
            icon="clear"
            title="GENERAL_CLEAR_CHANGES"
            message="WIDGETS_CLEAR_CHANGES"
            primary="GENERAL_CLEAR"
            content={elem.name}
            onSubmit={onClear}
            onClose={() => setShowClear(false)}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
WidgetPublish.propTypes = {
    open         : PropTypes.bool.isRequired,
    data         : PropTypes.object,
    currTongueID : PropTypes.number,
    onClose      : PropTypes.func.isRequired,
    onSubmit     : PropTypes.func.isRequired,
    onClear      : PropTypes.func.isRequired,
};

export default WidgetPublish;

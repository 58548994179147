import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import WidgetSection        from "./WidgetSection";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Widget Chat
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function WidgetChat(props) {
    const { onChange, onColor, onClear, forceClear, getText } = props;

    const { flows } = Store.useState("widget");
    const { data, errors } = Store.useState("widgetState");


    // Variables
    const hasChat        = Utils.isActive(data.showChat);
    const hasStart       = Utils.isActive(data.showStart);
    const bigButton      = hasChat && (Utils.isActive(data.requestName) || Utils.isActive(data.requestMessage));
    const requestName    = Utils.isActive(data.requestName);
    const requestMessage = Utils.isActive(data.requestMessage);
    const bigStartButton = Utils.isActive(data.bigStartButton);
    const startButton    = bigStartButton || (!requestName && !requestMessage);
    const hasLogo        = Boolean(data.logoFile);
    const hasNames       = Boolean(data.showNames && data.showNames !== "none");


    // Do the Render
    return <>
        <WidgetSection title="WIDGETS_GENERAL">
            <InputField
                type="toggle"
                name="showChat"
                label="WIDGETS_SHOW_CHAT"
                value={Number(data.showChat)}
                onChange={onChange}
                withBorder
            />
            <InputField
                isHidden={!hasChat}
                type="select"
                name="flowID"
                label="WIDGETS_CHAT_FLOW"
                value={data.flowID}
                error={errors.flowID}
                onChange={onChange}
                options={flows}
            />
        </WidgetSection>

        <WidgetSection
            isHidden={!hasChat || !hasStart}
            title="WIDGETS_START"
        >
            <InputField
                name="conversationText"
                label="WIDGETS_CONVERSATION_TEXT"
                value={getText("conversationText")}
                error={errors.conversationText}
                onInput={onChange}
                onClear={onClear}
                forceClear={forceClear("conversationText")}
                shrinkLabel
            />
            <InputField
                name="restartText"
                label="WIDGETS_RESTART_TEXT"
                value={getText("restartText")}
                error={errors.restartText}
                onInput={onChange}
                onClear={onClear}
                forceClear={forceClear("restartText")}
                shrinkLabel
            />
            <InputField
                type="toggle"
                name="requestName"
                label="WIDGETS_REQUEST_NAME"
                value={Number(data.requestName)}
                onChange={onChange}
                withBorder
            />
            <InputField
                isHidden={!requestName}
                name="requestNameText"
                label="WIDGETS_REQUEST_NAME_TEXT"
                value={getText("requestNameText")}
                error={errors.requestNameText}
                onInput={onChange}
                onClear={onClear}
                forceClear={forceClear("requestNameText")}
                shrinkLabel
            />
            <InputField
                type="toggle"
                name="requestMessage"
                label="WIDGETS_REQUEST_MESSAGE"
                value={Number(data.requestMessage)}
                onChange={onChange}
                withBorder
            />
            <InputField
                isHidden={!requestMessage}
                name="requestMessageText"
                label="WIDGETS_REQUEST_MESSAGE_TEXT"
                value={getText("requestMessageText")}
                error={errors.requestMessageText}
                onInput={onChange}
                onClear={onClear}
                forceClear={forceClear("requestMessageText")}
                shrinkLabel
            />
            <InputField
                isHidden={!bigButton}
                type="toggle"
                name="bigStartButton"
                label="WIDGETS_BIG_START_BUTTON"
                value={Number(data.bigStartButton)}
                onChange={onChange}
                withBorder
            />
            <InputField
                isHidden={!startButton}
                name="startButtonText"
                label="WIDGETS_START_BUTTON_TEXT"
                value={getText("startButtonText")}
                error={errors.startButtonText}
                onInput={onChange}
                onClear={onClear}
                forceClear={forceClear("startButtonText")}
                shrinkLabel
            />
        </WidgetSection>

        <WidgetSection
            isHidden={!hasChat}
            title="WIDGETS_CHAT"
        >
            <InputField
                isHidden={hasStart}
                type="toggle"
                name="autoStartChat"
                label="WIDGETS_AUTO_START_CHAT"
                value={Number(data.autoStartChat)}
                onChange={onChange}
                withBorder
            />
            <InputField
                isHidden={!hasLogo}
                type="toggle"
                name="showChatLogo"
                label="WIDGETS_SHOW_CHAT_LOGO"
                value={Number(data.showChatLogo)}
                onChange={onChange}
                withBorder
            />
            <InputField
                name="chatText"
                label="WIDGETS_CHAT_TEXT"
                value={getText("chatText")}
                error={errors.chatText}
                onInput={onChange}
                onClear={onClear}
                forceClear={forceClear("chatText")}
                shrinkLabel
            />

            <InputField
                type="select"
                name="showNames"
                label="WIDGETS_SHOW_NAMES"
                options="SELECT_SHOW_NAMES"
                value={data.showNames}
                onChange={onChange}
            />
            <InputField
                isHidden={!hasNames}
                name="botName"
                label="WIDGETS_BOT_NAME"
                value={getText("botName")}
                error={errors.botName}
                onInput={onChange}
                onClear={onClear}
                forceClear={forceClear("botName")}
                shrinkLabel
            />
            <InputField
                name="unreadText"
                label="WIDGETS_UNREAD_TEXT"
                value={getText("unreadText")}
                error={errors.unreadText}
                onInput={onChange}
                forceClear={forceClear("unreadText")}
                shrinkLabel
            />
            <InputField
                name="writingText"
                label="WIDGETS_WRITING_TEXT"
                value={getText("writingText")}
                error={errors.writingText}
                onInput={onChange}
                forceClear={forceClear("writingText")}
                shrinkLabel
            />
        </WidgetSection>

        <WidgetSection
            isHidden={!hasChat}
            title="WIDGETS_SEND"
        >
            <InputField
                name="sendText"
                label="WIDGETS_SEND_TEXT"
                value={getText("sendText")}
                error={errors.sendText}
                onInput={onChange}
                onClear={onClear}
                forceClear={forceClear("sendText")}
                shrinkLabel
            />
            <InputField
                type="toggle"
                name="showEmojiButton"
                label="WIDGETS_SHOW_EMOJI_BUTTON"
                value={Number(data.showEmojiButton)}
                onChange={onChange}
                withBorder
            />
            <InputField
                type="toggle"
                name="showFileButton"
                label="WIDGETS_SHOW_FILE_BUTTON"
                value={Number(data.showFileButton)}
                onChange={onChange}
                withBorder
            />
            <InputField
                type="toggle"
                name="showLocationButton"
                label="WIDGETS_SHOW_LOCATION_BUTTON"
                value={Number(data.showLocationButton)}
                onChange={onChange}
                withBorder
            />
        </WidgetSection>

        <WidgetSection
            isHidden={!hasChat}
            title="WIDGETS_COLORS"
        >
            <InputField
                type="color"
                name="buttonBgColor"
                label="WIDGETS_BUTTON_BG_COLOR"
                value={data.buttonBgColor}
                error={errors.buttonBgColor}
                onChange={onColor}
                onClear={onClear}
                forceClear={forceClear("buttonBgColor")}
                isRequired
            />
            <InputField
                type="color"
                name="buttonIconColor"
                label="WIDGETS_BUTTON_ICON_COLOR"
                value={data.buttonIconColor}
                error={errors.buttonIconColor}
                onChange={onColor}
                onClear={onClear}
                forceClear={forceClear("buttonIconColor")}
                isRequired
            />

            <InputField
                type="color"
                name="contactMessageBgColor"
                label="WIDGETS_CONTACT_MESSAGE_BG_COLOR"
                value={data.contactMessageBgColor}
                error={errors.contactMessageBgColor}
                onChange={onColor}
                onClear={onClear}
                forceClear={forceClear("contactMessageBgColor")}
                isRequired
            />
            <InputField
                type="color"
                name="contactMessageTextColor"
                label="WIDGETS_CONTACT_MESSAGE_TEXT_COLOR"
                value={data.contactMessageTextColor}
                error={errors.contactMessageTextColor}
                onChange={onColor}
                onClear={onClear}
                forceClear={forceClear("contactMessageTextColor")}
                isRequired
            />

            <InputField
                type="color"
                name="userMessageBgColor"
                label="WIDGETS_USER_MESSAGE_BG_COLOR"
                value={data.userMessageBgColor}
                error={errors.userMessageBgColor}
                onChange={onColor}
                onClear={onClear}
                forceClear={forceClear("userMessageBgColor")}
                isRequired
            />
            <InputField
                type="color"
                name="userMessageTextColor"
                label="WIDGETS_USER_MESSAGE_TEXT_COLOR"
                value={data.userMessageTextColor}
                error={errors.userMessageTextColor}
                onChange={onColor}
                onClear={onClear}
                forceClear={forceClear("userMessageTextColor")}
                isRequired
            />
        </WidgetSection>
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
WidgetChat.propTypes = {
    onChange   : PropTypes.func.isRequired,
    onColor    : PropTypes.func.isRequired,
    onClear    : PropTypes.func.isRequired,
    forceClear : PropTypes.func.isRequired,
    getText    : PropTypes.func.isRequired,
};

export default WidgetChat;

import React                from "react";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Hooks                from "Utils/Hooks";

// Components
import ClientEdit           from "Components/App/Admin/Client/ClientEdit";
import StoreList            from "Components/App/Client/Store/Store/StoreList";
import StorePage            from "Components/App/Client/Store/StorePage";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import UserRoute            from "Dashboard/Components/Route/UserRoute";
import SecondaryNav         from "Dashboard/Components/Navigation/SecondaryNav";
import NavigationList       from "Dashboard/Components/Navigation/NavigationList";
import NavigationItem       from "Dashboard/Components/Navigation/NavigationItem";



/**
 * The Store Container
 * @returns {React.ReactElement}
 */
function StoreContainer() {
    const { clientID } = Navigate.useParams();

    const navigate = Hooks.useGoto();

    const { closeMenu } = Store.useAction("core");
    const { isAnyAdmin, isAgent } = Store.useState("auth");

    const { elem : client } = Store.useState("client");
    const { fetchElem } = Store.useAction("client");

    const { elem : store, nav } = Store.useState("store");
    const { fetchNav } = Store.useAction("store");

    // The Current State
    const [ action, setAction ] = Action.useAction();


    // Load the Client
    React.useEffect(() => {
        if (clientID && clientID !== client.id) {
            fetchElem(clientID);
        }
    }, [ clientID ]);

    // Load the Stores
    React.useEffect(() => {
        handleLoad();
    }, []);

    // Handles the load
    const handleLoad = async () => {
        const list     = await fetchNav(clientID);
        const route    = path.substring(path.lastIndexOf("/") + 1);
        const routeUrl = NLS.urlToKey(route, "LIST", "ORDERS", "PRODUCTS", "CATEGORIES", "BANNERS", "PERSONALIZE");

        if (!routeUrl) {
            if (list.length) {
                gotoStore(list[0].key, list[0].status);
            } else {
                navigate("STORES", "LIST");
            }
        }
    };

    // Handles the Click
    const gotoStore = (storeID, status) => {
        const path = status === "Draft" ? "CATEGORIES" : "ORDERS";
        navigate("STORES", storeID, path);
    };

    // Handles the Action
    const handleAction = (action) => {
        if (action.isCE) {
            closeMenu();
            setAction(action);
        }
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        fetchElem(clientID);
        setAction();
    };


    // Variables
    const href   = Navigate.useFrom();
    const path   = Navigate.usePath();
    const isList = Boolean(path.endsWith(NLS.baseUrl("LIST")));


    // Do the Render
    return <>
        <SecondaryNav
            message={isAnyAdmin ? client.name : ""}
            fallback="STORES_NAME"
            href={isAnyAdmin ? href : "/"}
            onAction={handleAction}
            canEdit={isAnyAdmin}
        >
            <NavigationList isHidden={!nav.length}>
                {nav.map((elem) => <NavigationItem
                    key={elem.key}
                    message={elem.value}
                    onClick={() => gotoStore(elem.key, elem.status)}
                    isSelected={!isList && elem.key === store.id}
                    icon="store"
                />)}
            </NavigationList>

            <NavigationList isHidden={isAgent}>
                <NavigationItem
                    message="STORES_NAME"
                    url="LIST"
                    icon="list"
                />
            </NavigationList>
        </SecondaryNav>

        <Router type="CLIENT" withMain noFirst>
            <UserRoute
                url="STORE"
                component={StorePage}
            />
            <UserRoute
                url="STORE_TAB"
                component={StorePage}
            />
            <UserRoute
                url="STORE_SUB"
                component={StorePage}
            />

            <UserRoute
                isHidden={isAgent}
                url="LIST"
                component={StoreList}
            />
        </Router>

        <ClientEdit
            open={action.isEdit}
            elemID={clientID}
            onSubmit={handleEdit}
            onClose={setAction}
        />
    </>;
}

export default StoreContainer;

import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";
import DocumentType         from "Utils/DocumentType";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Document Create Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DocumentCreate(props) {
    const { open, onClose, onSubmit } = props;

    const { parents, filters, statuses } = Store.useState("document");
    const { editDocument } = Store.useAction("document");


    // The Initial Data
    const initialData = {
        documentID   : 0,
        parentID     : 0,
        language     : filters.language,
        documentType : filters.documentType,
        name         : "",
        content      : "",
        url          : "",
        position     : 0,
        status       : "Active",
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("document", initialData, editDocument, onSubmit);

    // Load the Data
    const { loading } = useDialog("document", open, 0, null, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="document"
        title="DOCUMENTS_CREATE_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="select"
            name="parentID"
            label="GENERAL_PARENT"
            value={data.parentID}
            error={errors.parentID}
            options={parents}
            onChange={handleChange}
        />
        <InputField
            isHidden={filters.documentType === DocumentType.RELEASES}
            name="url"
            label="DOCUMENTS_URL"
            value={data.url}
            error={errors.url}
            onChange={handleChange}
        />
        <InputField
            type="select"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            error={errors.status}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="number"
            name="position"
            label="GENERAL_POSITION"
            value={data.position}
            error={errors.position}
            onChange={handleChange}
            minValue={0}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DocumentCreate.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
};

export default DocumentCreate;

import React                from "react";
import PropTypes            from "prop-types";
import MediaType            from "Dashboard/Core/MediaType";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Components
import MediaField           from "Components/Utils/Inputs/MediaField";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Banner Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function BannerEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { elem } = Store.useState("store");
    const { categories, statuses } = Store.useState("storeBanner");
    const { editBanner } = Store.useAction("storeBanner");


    // The Initial Data
    const initialData = {
        storeID      : 0,
        bannerID     : 0,
        categoryID   : 0,
        name         : "",
        imageMobile  : "",
        imageDesktop : "",
        linkType     : "",
        url          : "",
        status       : "Active",
        position     : "",
    };

    // Handles the Edit
    const handleEdit = (data) => {
        return editBanner({
            ...data,
            storeID : elem.storeID,
        });
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("storeBanner", initialData, handleEdit, onSubmit);

    // Load the Data
    const { loading } = useDialog("storeBanner", open, elemID, { storeID : elem.storeID }, setElem);


    // Do the Render
    return <EditDialog
        open={open}
        icon="banner"
        title={elemID ? "BANNERS_EDIT_TITLE" : "BANNERS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <MediaField
            name="imageMobile"
            label="BANNERS_IMAGE_MOBILE"
            helperText="BANNERS_IMAGE_MOBILE_TIP"
            mediaType={MediaType.IMAGE}
            value={data.imageMobile}
            error={errors.imageMobile}
            onChange={handleChange}
            clientID={elem.clientID}
            isRequired
        />
        <MediaField
            name="imageDesktop"
            label="BANNERS_IMAGE_DESKTOP"
            helperText="BANNERS_IMAGE_DESKTOP_TIP"
            mediaType={MediaType.IMAGE}
            value={data.imageDesktop}
            error={errors.imageDesktop}
            onChange={handleChange}
            clientID={elem.clientID}
            isRequired
        />
        <InputField
            type="select"
            name="linkType"
            label="BANNERS_LINK_TYPE"
            options="SELECT_LINK_TYPES"
            value={data.linkType}
            error={errors.linkType}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={data.linkType !== "Category"}
            type="select"
            name="categoryID"
            label="CATEGORIES_SINGULAR"
            options={categories}
            value={data.categoryID}
            error={errors.categoryID}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={data.linkType !== "Url"}
            name="url"
            label="GENERAL_URL"
            value={data.url}
            error={errors.url}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="select"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            error={errors.status}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="number"
            name="position"
            label="GENERAL_POSITION"
            value={data.position}
            error={errors.position}
            onChange={handleChange}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
BannerEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default BannerEdit;

import React                from "react";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Hooks                from "Utils/Hooks";

// Components
import IntegrationEdit      from "../Dialogs/IntegrationEdit";

// Dashboard
import AlertDialog          from "Dashboard/Components/Dialogs/AlertDialog";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Integration Dialogs
 * @returns {React.ReactElement}
 */
function IntegrationDialogs() {
    const { integrationID, integrationTab } = Navigate.useParams();
    const navigate = Hooks.useGoto();

    const { openDetails } = Store.useAction("core");

    const { elem } = Store.useState("integration");
    const {
        fetchElem, activateIntegration, publishIntegration,
        clearIntegration, pauseIntegration, deleteIntegration,
    } = Store.useAction("integration");

    const { inActivate, action } = Store.useState("integrationState");
    const { setAction, setInActivate, setInPublish } = Store.useAction("integrationState");

    const { publishErrors } = Store.useState("integrationEditor");
    const { fetchEditor } = Store.useAction("integrationEditor");

    const { publishErrors : flowPublishErrors } = Store.useState("flowEditor");
    const { setInPublish : setFlowInPublish } = Store.useAction("flowState");


    // Handles the Edit Submit
    const handleEdit = async (response) => {
        setAction();
        if (response.integrationID !== elem.id) {
            navigate("SETTINGS", "INTEGRATIONS", response.integrationID, "EDIT");
        } else {
            fetchElem(elem.id);
        }
    };

    // Handles the Activate Submit
    const handleActivate = async () => {
        setAction();
        const result = await activateIntegration(elem.id);
        if (result.success) {
            setInActivate(false);
            setFlowInPublish(false);
            fetchElem(elem.id);
        }
    };

    // Handles the Publish Submit
    const handlePublish = async () => {
        setAction();
        const result = await publishIntegration(elem.id);
        if (result.success) {
            setInPublish(false);
            setFlowInPublish(false);
            fetchElem(elem.id);
        }
    };

    // Handles the Clear Submit
    const handleClear = async () => {
        setAction();
        const result = await clearIntegration(elem.id);
        if (result.success) {
            fetchEditor(elem.id);
        }
    };

    // Handles the Pause Submit
    const handlePause = async () => {
        setAction();
        const result = await pauseIntegration(elem.id);
        if (result.success) {
            fetchElem(elem.id);
        }
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        setAction();
        const result = await deleteIntegration(elem.id);
        if (result.success) {
            navigate("SETTINGS", "INTEGRATIONS");
        }
    };

    // Handles the Errors
    const handleErrors = () => {
        setAction();
        if (publishErrors.length) {
            if (!Navigate.isUrl("EDIT", integrationTab)) {
                navigate("SETTINGS", "INTEGRATIONS", integrationID, "EDIT");
            } else {
                openDetails();
            }
        } else if (flowPublishErrors.length) {
            if (!Navigate.isUrl("FLOW_ONE", integrationTab)) {
                navigate("SETTINGS", "INTEGRATIONS", integrationID, "FLOW_ONE");
            } else {
                openDetails();
            }
        }
    };

    // Returns the Error Message
    const getErrorsText = () => {
        const parts = [
            publishErrors.length ? "INTEGRATION" : "FLOW",
            inActivate ? "ACTIVATE" : "PUBLISH",
            "PANEL",
        ];
        return parts.map((text) => NLS.get(`INTEGRATIONS_ERRORS_${text}`)).join(" ");
    };



    // Do the Render
    return <>
        <IntegrationEdit
            open={action.isEdit || action.isCopy}
            isCopy={action.isCopy}
            elemID={elem.id}
            clientID={elem.clientID}
            onSubmit={handleEdit}
            onClose={setAction}
        />

        <ConfirmDialog
            open={action.isActivate}
            icon="check"
            title="INTEGRATIONS_ACTIVATE_TITLE"
            message="INTEGRATIONS_ACTIVATE_TEXT"
            content={elem.name}
            onSubmit={handleActivate}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isPublish}
            icon="publish"
            title="GENERAL_PUBLISH_CHANGES"
            message="INTEGRATIONS_PUBLISH_CHANGES"
            content={elem.name}
            onSubmit={handlePublish}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isClear}
            icon="clear"
            title="GENERAL_CLEAR_CHANGES"
            message="INTEGRATIONS_CLEAR_CHANGES"
            primary="GENERAL_CLEAR"
            content={elem.name}
            onSubmit={handleClear}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isPause}
            icon="pause"
            title="INTEGRATIONS_PAUSE_TITLE"
            message="INTEGRATIONS_PAUSE_TEXT"
            content={elem.name}
            onSubmit={handlePause}
            onClose={setAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="INTEGRATIONS_DELETE_TITLE"
            message="INTEGRATIONS_DELETE_TEXT"
            content={elem.name}
            onSubmit={handleDelete}
            onClose={setAction}
        />

        <AlertDialog
            open={action.isError}
            icon={inActivate ? "check" : "publish"}
            title={inActivate ? "INTEGRATIONS_ACTIVATE_TITLE" : "GENERAL_PUBLISH_CHANGES"}
            message={getErrorsText()}
            onClose={handleErrors}
        />
    </>;
}

export default IntegrationDialogs;

import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Period               from "Dashboard/Utils/Period";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";
import Hooks                from "Utils/Hooks";

// Dashboard
import FilterList           from "Dashboard/Components/Filter/FilterList";
import FilterItem           from "Dashboard/Components/Filter/FilterItem";



/**
 * The Dashboard Filters
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DashboardFilters(props) {
    const { type, isLoading, filters } = props;

    const clientID  = Hooks.useClientID();
    const partnerID = Hooks.usePartnerID();

    const { isAgent, isAnyAdmin } = Store.useState("auth");

    const {
        loading, edition, initialFilter,
        partners, clients, idsPerPartner, providers, channels, hotels, users,
    } = Store.useState("dashboardFilter");

    const { fetchFilterData, saveFilters } = Store.useAction("dashboardFilter");


    // The Current State
    const [ filterPartnerID, setFilterPartnerID ] = React.useState(0);


    // Component Did Mount
    React.useEffect(() => {
        fetchFilterData(clientID, partnerID);
    }, []);

    // Data Updated
    React.useEffect(() => {
        handleChange(filters);
    }, [ edition ]);


    // Handles the Change
    const handleChange = (filters) => {
        if (filters.partnerID && !filters.partnerID.length) {
            setFilterPartnerID(filters.partnerID);
        } else {
            setFilterPartnerID(0);
        }
    };

    // Handles the Filter Submit
    const handleSubmit = (filters) => {
        saveFilters({
            type      : type,
            clientID  : clientID,
            partnerID : partnerID,
            filters   : JSON.stringify({ ...initialFilter, ...filters }),
        });
    };


    // Variables
    const isReport   = type === "reports";
    const partnerIDs = filterPartnerID ? [ filterPartnerID ] : [];
    const getClients = Utils.useSelectList(loading, partnerIDs, clients, idsPerPartner);


    // Do the Render
    if (isLoading || loading) {
        return <React.Fragment />;
    }
    return <FilterList
        initialData={initialFilter}
        values={filters}
        onChange={handleChange}
        onFilter={handleSubmit}
        clearButton
        hideButton
    >
        <FilterItem
            type="select"
            name="period"
            label="PERIOD_NAME"
            options={isReport ? Period.getLastSelect(true) : Period.getSimpleSelect()}
            hideClear
        />

        <FilterItem
            isHidden={!partners.length}
            type="select"
            name="partnerID"
            label="PARTNERS_SINGULAR"
            options={partners}
            noneText="GENERAL_ALL"
            shrinkLabel
            hasClear
        />
        <FilterItem
            isHidden={!clients.length}
            type="select"
            name="clientID"
            label="CLIENTS_SINGULAR"
            options={getClients()}
            noneText="GENERAL_ALL"
            shrinkLabel
            hasClear
        />
        <FilterItem
            isHidden={!providers.length}
            type="select"
            name="providerName"
            label="GENERAL_PLATFORM"
            options={Commons.getPlatformSelect(providers)}
            noneText="GENERAL_ALL_FEM"
            shrinkLabel
            hasClear
        />

        <FilterItem
            isHidden={!channels.length}
            type="select"
            name="providerName"
            label="GENERAL_PLATFORM"
            options={Commons.getPlatformSelect(channels)}
            noneText="GENERAL_ALL_FEM"
            shrinkLabel
            hasClear
        />
        <FilterItem
            isHidden={!channels.length}
            type="select"
            name="channelID"
            label="CHANNELS_SINGULAR"
            options={Commons.getChannelSelect(channels, false)}
            noneText="GENERAL_ALL"
            shrinkLabel
            hasClear
        />
        <FilterItem
            isHidden={!hotels.length}
            type="select"
            name="hotelID"
            label="HOTELS_SINGULAR"
            options={hotels}
            noneText="GENERAL_ALL"
            shrinkLabel
            hasClear
        />
        <FilterItem
            isHidden={!users.length || isAgent || !!filters.onlyMine}
            type="select"
            name="userID"
            label="USERS_SINGULAR"
            options={users}
            noneText="GENERAL_ALL"
            shrinkLabel
            hasClear
        />
        <FilterItem
            isHidden={isAgent || isAnyAdmin}
            type="select"
            name="onlyMine"
            label="GENERAL_ONLY_MINE"
            options="GENERAL_YES_BLANK"
            noneText="GENERAL_NO"
            shrinkLabel
            hasClear
        />
    </FilterList>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DashboardFilters.propTypes = {
    type      : PropTypes.string.isRequired,
    isLoading : PropTypes.bool.isRequired,
    filters   : PropTypes.object,
};

export default DashboardFilters;

import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import ReportsChart         from "./ReportsChart";
import DashboardFilters     from "../Utils/DashboardFilters";

// Dashboard
import Icon                 from "Dashboard/Components/Common/Icon";



// Styles
const Container = Styled.section.attrs(({ withNavigation, hasTabs }) => ({ withNavigation, hasTabs }))`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--main-gap);
    overflow: auto;


    ${(props) => props.hasTabs ? `
        height: calc(var(--page-height) - var(--tabs-table) - var(--filter-height));
    ` : `
        height: calc(var(--page-height) - var(--filter-height));
    `}

    ${(props) => props.withNavigation ? `
        @media (max-width: 1600px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 1100px) {
            grid-template-columns: repeat(1, 1fr);
        }

        @media (max-width: 1000px) {
            grid-template-columns: repeat(2, 1fr);
        }
    ` : `
        @media (max-width: 1300px) {
            grid-template-columns: repeat(2, 1fr);
        }
    `}

    @media (max-width: 800px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

const Content = Styled.div.attrs(({ withTitle }) => ({ withTitle }))`
    display: flex;
    flex-direction: column;
    gap: 4px;
    box-sizing: border-box;

    ${(props) => props.withTitle && `
        padding: 24px;
        border: var(--border-width) solid var(--border-color-light);
        border-radius: var(--border-radius);
    `}
`;

const Header = Styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -8px;
    min-height: 30px;
`;

const Title = Styled.h3`
    margin: 0;
    display: flex;
    align-items: center;
    gap: 8px;
`;

const Help = Styled(Icon)`
    font-size: 18px;
    cursor: pointer;
`;

const Total = Styled.h3`
    margin: 0;
    font-size: 24px;
`;



/**
 * The Reports Content
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ReportsContent(props) {
    const { isHidden, type, withNavigation, withLinks, hasTabs } = props;

    const elemID = Navigate.useOneParam(type);

    const { loading, edition, reports, dayLabels, filters } = Store.useState("dashboardReport");
    const { startLoader, fetchList } = Store.useAction("dashboardReport");


    // The References
    const timerRef = React.useRef(0);

    // The Current State
    const [ update, setUpdate ] = React.useState(0);


    // Handle the Auto-update
    React.useEffect(() => {
        startLoader();
    }, [ isHidden ]);

    React.useEffect(() => {
        if (!isHidden) {
            fetchList(type, elemID);
            Utils.setUpdateTimeout(timerRef, setUpdate, update, 20);
        }
        return () => {
            Utils.clearTimeout(timerRef);
        };
    }, [ isHidden, update, edition ]);


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <>
        <DashboardFilters
            type="reports"
            isLoading={loading}
            filters={filters}
        />
        <Container
            className="light-scrollbars"
            withNavigation={withNavigation}
            hasTabs={hasTabs}
        >
            {reports.map(({ id, withTitle, name, tooltip, total, type, ...chart }) => <Content
                key={id}
                withTitle={withTitle}
            >
                {withTitle && <Header>
                    <Title>
                        {name}
                        {!!tooltip && <Help
                            icon="help"
                            tooltip={tooltip}
                        />}
                    </Title>
                    <Total>{total}</Total>
                </Header>}

                <ReportsChart
                    type={type}
                    isStacked={chart.isStacked}
                    title={chart.title}
                    labels={chart.labels.length ? chart.labels : dayLabels}
                    icons={chart.icons}
                    dataset={chart.dataset}
                    extras={chart.extras}
                    colors={chart.colors}
                    suffix={chart.suffix}
                    urls={chart.urls}
                    withLinks={withLinks}
                />
            </Content>)}
        </Container>
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ReportsContent.propTypes = {
    isHidden       : PropTypes.bool,
    type           : PropTypes.string.isRequired,
    withNavigation : PropTypes.bool,
    withLinks      : PropTypes.bool,
    hasTabs        : PropTypes.bool,
};

/**
 * The Default Properties
 * @type {Object} defaultProps
 */
ReportsContent.defaultProps = {
    isHidden       : false,
    withNavigation : false,
    withLinks      : false,
    hasTabs        : false,
};

export default ReportsContent;

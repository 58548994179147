import React                from "react";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Hooks                from "Utils/Hooks";

// Components
import FlowHeader           from "./View/FlowHeader";
import FlowReport           from "./View/FlowReport";
import FlowConversations    from "./View/FlowConversations";
import FlowDialogs          from "./View/FlowDialogs";
import FlowEditor           from "./Editor/FlowEditor";
import AssistantRunContent  from "Components/App/Log/AssistantRunLog/AssistantRunContent";
import FetchLogContent      from "Components/App/Log/FetchLog/FetchLogContent";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";



/**
 * The Flow Page
 * @returns {React.ReactElement}
 */
function FlowPage() {
    const { flowID, flowTab } = Navigate.useParams();
    const navigate = Hooks.useGoto();

    const { charging, elem } = Store.useState("flow");

    const { fetchData } = Store.useAction("flow");
    const { setInPublish } = Store.useAction("flowState");
    const { hasAssistantAI, hasIntegrations } = Store.useState("permission");


    // Load the Data
    React.useEffect(() => {
        handleLoad(flowID);
        setInPublish(false);
    }, [ flowID ]);

    // Handle the Load
    const handleLoad = async (flowID) => {
        const { elem } = await fetchData(flowID);
        if (!elem) {
            return;
        }

        if (elem.isDraft) {
            navigate("SETTINGS", "FLOWS", flowID, "EDIT");
        } else if (!flowTab) {
            navigate("SETTINGS", "FLOWS", flowID, "REPORT");
        }
    };

    // Handles the Tab
    const handleTab = (url) => {
        navigate("SETTINGS", "FLOWS", flowID, url);
    };


    // Variables
    const isReport        = Navigate.isUrl("REPORT",         flowTab);
    const isConversations = Navigate.isUrl("CONVERSATIONS",  flowTab);
    const isAssistants    = Navigate.isUrl("LOG_ASSISTANTS", flowTab);
    const isFetches       = Navigate.isUrl("LOG_FETCHES",    flowTab);
    const isEdit          = Navigate.isUrl("EDIT",           flowTab);


    // Do the Render
    return <>
        <Main withNavigation>
            <FlowHeader levels={2} isEdit={isEdit} />

            <Content isLoading={charging}>
                <TabList
                    isHidden={!elem.hasTabs}
                    selected={flowTab}
                    onClick={handleTab}
                >
                    <TabItem
                        message="GENERAL_REPORT"
                        url="REPORT"
                    />
                    <TabItem
                        message="CONVERSATIONS_NAME"
                        url="CONVERSATIONS"
                    />
                    <TabItem
                        isHidden={!hasAssistantAI || !elem.hasAssistant}
                        message="ASSISTANTS_NAME"
                        url="LOG_ASSISTANTS"
                    />
                    <TabItem
                        isHidden={!hasIntegrations || !elem.hasFetch}
                        message="FETCHES_LOG_TITLE"
                        url="LOG_FETCHES"
                    />
                    <TabItem
                        message="GENERAL_EDIT"
                        url="EDIT"
                    />
                </TabList>

                {isReport && <FlowReport />}
                {isConversations && <FlowConversations />}
                {isAssistants && <AssistantRunContent type="FLOW" />}
                {isFetches && <FetchLogContent type="FLOW" />}
                {isEdit && <FlowEditor
                    flowID={flowID}
                    hasTabs={elem.hasTabs || false}
                />}
            </Content>
        </Main>

        <FlowDialogs />
    </>;
}

export default FlowPage;

import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";

// Components
import FlowSimulationChat   from "./FlowSimulationChat";
import FlowSimulationSend   from "./FlowSimulationSend";
import FlowSimulationEnd    from "./FlowSimulationEnd";
import FlowSimulationRestart from "./FlowSimulationRestart";

// Dashboard
import DetailList           from "Dashboard/Components/Details/DetailList";
import Button               from "Dashboard/Components/Form/Button";



// Styles
const Container = Styled(DetailList).attrs(({ hasExternalTabs, hasInternalTabs }) => ({ hasExternalTabs, hasInternalTabs }))`
    --simulation-height: ${(props) => props.hasExternalTabs ? "var(--page-height-tabs)" : "var(--page-height)"};
    --simulation-tabs: ${(props) => props.hasInternalTabs ? "calc(var(--tabs-dialog) + 16px)" : "0px"};
    --simulation-extras: calc(41px + 32px + 2px + var(--simulation-tabs));

    .details-content {
        display: flex;
        flex-direction: column;
        height: calc(var(--simulation-height) - var(--simulation-extras));
        padding: 0;
    }

    @media (max-width: 1200px) {
        .details-content {
            height: calc(100dvh - var(--simulation-extras));
        }
    }
`;

const Footer = Styled.footer`
    display: flex;
    padding: 8px 12px;
    gap: 8px;
    border-top: 1px solid var(--border-color-light);

    .btn {
        flex-grow: 2;
    }
`;



/**
 * The Flow Simulation
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowSimulation(props) {
    const { open, hasExternalTabs, hasInternalTabs, onEnd } = props;

    const { closeDetails } = Store.useAction("core");

    const { simulationID, currentNodeID, lastUpdate } = Store.useState("flowSimulation");
    const { fetchSimulation, fetchMessages, sendMessage } = Store.useAction("flowSimulation");


    // The References
    const timerRef = React.useRef(null);

    // The Current State
    const [ submits, setSubmits ] = React.useState(0);
    const [ dialog,  setDialog  ] = React.useState("");
    const [ update,  setUpdate  ] = React.useState(0);


    // Add/Remove the Auto-update
    React.useEffect(() => {
        if (open) {
            setAutoUpdate();
        } else {
            Utils.clearTimeout(timerRef);
        }
        return () => {
            if (open) {
                Utils.clearTimeout(timerRef);
            }
        };
    }, [ open ]);

    // Load the Data
    React.useEffect(() => {
        if (simulationID) {
            fetchSimulation(simulationID);
        }
    }, [ simulationID ]);

    // Handle the Update
    React.useEffect(() => {
        if (simulationID && lastUpdate) {
            fetchMessages(simulationID, lastUpdate);
            setAutoUpdate();
        }
    }, [ update ]);

    // Handle the Follow
    React.useEffect(() => {
        if (currentNodeID) {
            Commons.scrollToNode(currentNodeID, "instant");
        }
    }, [ currentNodeID ]);


    // Sets an auto-update
    const setAutoUpdate = () => {
        Utils.setTimeout(timerRef, () => {
            setUpdate(update + 1);
        }, 5 * 1000);
    };

    // Handles the Submit
    const handleSubmit = ({ message, file, latitude = 0, longitude = 0 }) => {
        setSubmits(submits + 1);
        sendMessage({
            simulationID, lastUpdate,
            message, file,
            latitude, longitude,
        });
        window.setTimeout(() => {
            setUpdate(update + 1);
        }, 1000);
    };

    // Handles the Option
    const handleOption = (payload, message, replyToID) => {
        setSubmits(submits + 1);
        sendMessage({
            simulationID, lastUpdate,
            message, payload, replyToID,
        });
    };

    // Handles the Simulation End
    const handleEnd = () => {
        setDialog("");
        closeDetails();
        onEnd();
        Utils.clearTimeout(timerRef);
    };



    // Do the Render
    if (!open) {
        return <React.Fragment />;
    }
    return <>
        <Container
            icon="simulate"
            message="FLOWS_SIMULATION_NAME"
            hasExternalTabs={hasExternalTabs}
            hasInternalTabs={hasInternalTabs}
        >
            <FlowSimulationChat
                submits={submits}
                onOption={handleOption}
            />
            <FlowSimulationSend
                onSubmit={handleSubmit}
            />

            <Footer>
                <Button
                    variant="primary"
                    message="FLOWS_SIMULATION_RESTART"
                    onClick={() => setDialog("restart")}
                    isSmall
                />
                <Button
                    variant="primary"
                    message="FLOWS_SIMULATION_END"
                    onClick={() => setDialog("end")}
                    isSmall
                />
            </Footer>
        </Container>

        <FlowSimulationRestart
            open={dialog === "restart"}
            onSubmit={() => setDialog("")}
            onClose={() => setDialog("")}
        />
        <FlowSimulationEnd
            open={dialog === "end"}
            onSubmit={handleEnd}
            onClose={() => setDialog("")}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowSimulation.propTypes = {
    open            : PropTypes.bool.isRequired,
    hasExternalTabs : PropTypes.bool.isRequired,
    hasInternalTabs : PropTypes.bool.isRequired,
    onEnd           : PropTypes.func.isRequired,
};

export default FlowSimulation;
